.meta-gift-header {
  // display: flex;
  // align-items: center;
  // background: #ffffff;
  // box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.06);
  // height: 50px;
  // padding: 0 1rem;
  .btn {
    font-family: 'Conv_Averta-Bold', Arial, sans-serif;
    font-weight: 700;
  }
  
  .align-items-center {
    padding-right: calc(1.5rem * 0.1);
    padding-left: calc(1.5rem * 0.1);
  }
  .bg_blue {
    background-color: #e7f3fc;
  }
  .d-flex {
    display: flex !important;
  }
  .btn.btn-lg {
    padding: 12px 28px;
    font-size: 17px;
  }
  .btn {
    padding: 8px 24px;

    font-size: 16px;
    transition: all 0.5s;
    font-family: 'Mandali', sans-serif;
  }
  .btn-warning{
    --bs-btn-border-radius: 80px;
    padding: 16px;
    border-color: #d1212a;
  }
  .btn-warning:hover{
    background-color: #1377bb;
      border-color: #1377bb;
    color:#FFFFFF;
  }
  .nav-link {
    color: #333333;

    &:focus,
    &:hover {
      color: #333333;
    }
    b {
      color: #ff9f01;
    }
  }
  .border-left {
    border-left: 1px solid #dee2e6 !important;
  }

  .navbar-custom {
    padding: 10px 0px;
    padding-right: 0 !important;
    width: 100%;
    border-radius: 0px;
    z-index: 999;
    margin-bottom: 0px;
    transition: all 0.5s ease-in-out;

    &.fixed-top {
      position: fixed;
      top: 0;
      right: 0;
      left: 0;
      z-index: 1030;
    }

    &.nav-sticky {
      margin-top: 0px;
      padding: 10px 0px;
      background-color: #fff;
      box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.2);
      color: #343a40 !important;
      padding-bottom: 0px;
    }
    &#navbar-sticky {
      img {
        cursor: pointer;
      }
    }
  }
}
